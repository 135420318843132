import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/Hero/Hero";
import Description from "../components/Description/Description";

import videoMakingVideoHevc from "../videos/video_making/video-making-h265.mp4";
import videoMakingVideoWebm from "../videos/video_making/video-making-vp9.webm";
import videoMakingVideoMp4 from "../videos/video_making/video-making-h264.mp4";
import videoMakingPoster from "../videos/video_making/video-making-poster.jpg";

export const videoImage = graphql`
  fragment videoImage on File {
    childImageSharp {
      fluid(maxHeight: 1200, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const VideoMaking = ({ data }) => (
  <Layout pageUrl="video-making">
    <SEO title="Video" />
    <Hero
      home={false}
      pageTitle="Video Making"
      videoSrcHevc={videoMakingVideoHevc}
      videoSrcWebm={videoMakingVideoWebm}
      videoSrcMp4={videoMakingVideoMp4}
      videoPoster={videoMakingPoster}
    />
    <Description
      heading="Il video giusto per raccontare il tuo brand"
      description="Il nostro servizio di Video Making permettere di girare video contemporanei e aggiornati sulle nuove tendenze dell'immagine, ottimizzando i tempi e i costi di produzione. Il team di Parallel Milano garantisce la realizzazione di piccole e medie produzioni per campagne, social media, e-commerce e backstage, provvedendo anche al montaggio e alla post produzione."
      noPics
    />
  </Layout>
);

export default VideoMaking;
